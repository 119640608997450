<template>
  <div>
    <v-app :class="{ inverted: isInverted, grayscale: isGrayscale }">
      <div v-if="!gameStarted">
        <h1>Welcome to the Flag Guessing Game! <p v-if="learningMode">(Learning Mode)</p></h1>
        <p>Test your knowledge of world flags. You will be given a country's name, and you need to select the correct flag from the options provided.</p>
        <p>In Learning Mode there is no flag limit and difficult flags are sampled more frequently</p>
        <div class="button-container">
          <v-btn @click="startGame(false, false, false)" color="primary">Start Game</v-btn>
          <v-btn @click="startGame(false, true, false)" color="primary">Start Game (Grayscale)</v-btn>
          <v-btn @click="startGame(false, false, true)" color="primary">Start Game (Inverted)</v-btn>
          <v-btn @click="startGame(true, false, false)" color="primary">Learning Mode</v-btn>
          <v-btn @click="clearHistory" color="secondary">Clear History</v-btn>
        </div>
      </div>

      <div v-else>
        <div class="container">
          <v-btn v-if="!learningMode" @click="shareChallenge" color="secondary">Share Challenge</v-btn>
          <v-btn @click="backToMenu" color="secondary">Back</v-btn>
          <h1>Guess the Flag</h1>
          <h2>Question {{ currentQuestion + 1 }}/{{ totalQuestions }}</h2>
          <h2>Which flag belongs to {{ currentCountry.name }}?</h2>
        </div>
        <div class="flag-grid-container">
          <div class="flag-grid">
            <div 
              v-for="(flag, index) in flagOptions" 
              :key="index" 
              class="flag" 
              :class="{ correct: isCorrect(flag), incorrect: isIncorrect(flag), clickable: !clickedFlag }" 
              @click="checkAnswer(flag)"
            >
              <div>{{ flag }}</div>
              <div v-if="clickedFlag !== null" class="flagtext">{{ getCountryName(flag) }}</div>
            </div>
          </div>
        </div>
        <div class="container">
          <v-btn @click="nextQuestion" color="primary">Next</v-btn>
        </div>
      </div>

      <v-dialog v-model="gameOver" max-width="600px">
        <v-card>
          <v-card-title class="headline">Game Over!</v-card-title>
          <v-card-text>
            <p>You got {{ correctAnswers }} out of {{ totalQuestions }} correct.</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="startGame(this.learningMode, this.grayscale, this.inverted)" color="primary">New Game</v-btn>
            <v-btn @click="backToMenu()" color="primary">Main Menu</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showShareDialog" max-width="600px">
        <v-card>
          <v-card-title class="headline">Share Challenge</v-card-title>
          <v-card-text>
            <p>Copy and share this link to challenge your friends:</p>
            <v-text-field v-model="shareUrl" readonly></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="copyToClipboard" color="primary">Copy Link</v-btn>
            <v-btn @click="showShareDialog = false" color="secondary">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { flags } from '../flagData.js';

export default {
  data() {
    return {
      gameStarted: false,
      learningMode: true,
      isGrayscale: false,
      isInverted: false,
      currentCountry: null,
      previousCountry: null,
      flagOptions: [],
      result: '',
      currentQuestion: 0,
      totalQuestions: 20,
      correctAnswers: 0,
      gameOver: false,
      clickedFlag: null,
      flagStats: {},
      kWrong: 2.0,  // Configurable constant for increasing logit on wrong answer
      kRight: 1.0,  // Configurable constant for decreasing logit on right answer
      seed: '',
      rng: null,
      showShareDialog: false,
      shareUrl: ''
    };
  },
  methods: {
    clearHistory() {
      localStorage.removeItem('flagStats');
    },
    startGame(learningMode, grayscale = false, inverted = false, seed = null) {
      console.log('Starting game');
      this.learningMode = learningMode;
      this.isGrayscale = grayscale;
      this.isInverted = inverted;
      console.log('Seed in', this.seed);
      this.seed = [null, undefined].includes(seed) ? Math.floor(Math.random() * 1000) : seed;
      console.log('Seed set', this.seed);
      this.rng = function(s) {
        return function() {
          console.log(s);
          s = Math.sin(s) * 10000;
          return s - Math.floor(s);
        };
      }(this.seed);
      this.gameStarted = true;
      this.currentQuestion = 0;
      this.correctAnswers = 0;
      this.gameOver = false;
      this.previousCountry = null;
      this.loadFlagStats();
      this.nextFlag();
    },
    getRandomFlags() {
      const currentFlag = this.currentCountry.emoji;
      const similarFlags = this.currentCountry.similar;
      let options = [currentFlag, ...similarFlags];
      options = options.sort(() => this.rng() - 0.5).slice(0, 9); // Ensure 9 options
      this.flagOptions = options.sort(() => this.rng() - 0.5); // Shuffle again
    },
    getCountryName(flagEmoji) {
      const country = flags.find(country => country.emoji === flagEmoji);
      return country ? country.name : '';
    },
    checkAnswer(flag) {
      if (this.clickedFlag !== null) return; // Prevent changing flag by clicking something else
      this.clickedFlag = flag;
      const correctFlag = this.currentCountry.emoji;
      const oldProbs = this.calculateProbabilities();
      if (flag === correctFlag) {
        this.result = 'Correct!';
        this.correctAnswers++;
        this.updateFlagStats(flag, true);
      } else {
        this.result = 'Incorrect!';
        this.updateFlagStats(flag, false);
        this.updateFlagStats(correctFlag, false);
      }
      const newProbs = this.calculateProbabilities();
      this.logProbabilityChanges(oldProbs, newProbs, flag, correctFlag);
    },
    isCorrect(flag) {
      return flag === this.currentCountry.emoji && (this.clickedFlag !== null || this.result === 'Incorrect!');
    },
    isIncorrect(flag) {
      return this.clickedFlag === flag && flag !== this.currentCountry.emoji;
    },
    nextFlag() {
      do {
        this.currentCountry = this.learningMode ? this.sampleFlagWithLogits() : this.sampleRandomFlag();
      } while (this.currentCountry === this.previousCountry);
      
      this.previousCountry = this.currentCountry;
      this.getRandomFlags();
      this.result = '';
      this.clickedFlag = null;
    },
    nextQuestion() {
      if (this.currentQuestion + 1 >= this.totalQuestions) {
        this.gameOver = true;
      } else {
        this.currentQuestion++;
        this.nextFlag();
      }
    },
    loadFlagStats() {
      const storedStats = localStorage.getItem('flagStats');
      this.flagStats = storedStats ? JSON.parse(storedStats) : {};
      flags.forEach(flag => {
        if (!this.flagStats[flag.emoji]) {
          this.flagStats[flag.emoji] = { correct: 0, wrong: 0, logit: 0 };
        }
      });
    },
    saveFlagStats() {
      localStorage.setItem('flagStats', JSON.stringify(this.flagStats));
    },
    updateFlagStats(flag, isCorrect) {
      if (!this.flagStats[flag]) {
        this.flagStats[flag] = { correct: 0, wrong: 0, logit: 0 };
      }
      if (isCorrect) {
        this.flagStats[flag].correct++;
        this.flagStats[flag].logit -= this.kRight;  // Decrease logit for correct answer
      } else {
        this.flagStats[flag].wrong++;
        this.flagStats[flag].logit += this.kWrong;  // Increase logit for wrong answer
      }
      this.saveFlagStats();
    },
    sampleFlagWithLogits() {
      const logits = flags.map(flag => this.flagStats[flag.emoji].logit);
      const maxLogit = Math.max(...logits);
      const expLogits = logits.map(l => Math.exp(l - maxLogit));
      const sumExpLogits = expLogits.reduce((a, b) => a + b, 0);
      const probs = expLogits.map(e => e / sumExpLogits);
      
      const r = this.rng();
      let cumSum = 0;
      for (let i = 0; i < probs.length; i++) {
        cumSum += probs[i];
        if (r <= cumSum) {
          return flags[i];
        }
      }
      return flags[flags.length - 1]; // Fallback to last flag (should rarely happen)
    },
    sampleRandomFlag() {
      return flags[Math.floor(this.rng() * flags.length)];
    },
    calculateProbabilities() {
      const logits = flags.map(flag => this.flagStats[flag.emoji].logit);
      const maxLogit = Math.max(...logits);
      const expLogits = logits.map(l => Math.exp(l - maxLogit));
      const sumExpLogits = expLogits.reduce((a, b) => a + b, 0);
      return flags.reduce((acc, flag, index) => {
        acc[flag.emoji] = expLogits[index] / sumExpLogits;
        return acc;
      }, {});
    },
    logProbabilityChanges(oldProbs, newProbs, chosenFlag, correctFlag) {
      console.log(`Probability changes for question ${this.currentQuestion + 1}:`);
      console.log(`Chosen flag: ${chosenFlag}, Correct flag: ${correctFlag}`);
      console.log(`Chosen flag probability: ${oldProbs[chosenFlag].toFixed(4)} -> ${newProbs[chosenFlag].toFixed(4)}`);
      console.log(`Correct flag probability: ${oldProbs[correctFlag].toFixed(4)} -> ${newProbs[correctFlag].toFixed(4)}`);
    },
    shareChallenge() {
      const gameType = this.isGrayscale ? 'grayscale' : this.isInverted ? 'inverted' : 'normal';
      this.shareUrl = `${window.location.origin}${window.location.pathname}?seed=${this.seed}&type=${gameType}`;
      this.showShareDialog = true;
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.shareUrl);
    },
    readSeedFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      let seed = urlParams.get('seed');
      if (seed !== null) seed = parseInt(seed);

      const type = urlParams.get('type');
      if (type) {
        this.startGame(false, type === 'grayscale', type === 'inverted', seed);
      }
    },
    backToMenu() {
      // window.location.href = window.location.origin + '/';
      this.gameStarted = false;
      this.gameOver = false;
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('type');
      urlParams.delete('seed');
    }
  },
  beforeMount() {
    this.readSeedFromUrl();
    this.loadFlagStats();
  }
};
</script>

<style>
.container {
  margin: 1em;
  padding: 0 15px;
  max-width: 100%;
  box-sizing: border-box;
}

.flag-grid-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.flag-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.flag {
  line-height: 1.0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  font-size: 10rem;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.grayscale .flag > div {
  filter: grayscale(1);
}

.inverted .flag > div {
  filter: invert(1);
}

.flag.correct {
  background-color: green;
  color: white;
}

.flag.incorrect {
  background-color: red;
  color: white;
}

.flag:hover {
  background-color: #f0f0f0;
}

.flag.correct:hover {
  background-color: green;
  color: white;
}

.flag.incorrect:hover {
  background-color: red;
  color: white;
}

.flagtext {
  font-size: 0.8rem;
  margin-top: 5px;
  text-align: center;
}

.flag.clickable:hover {
  background-color: #f0f0f0;
}

@media (max-width: 800px) {
  .flag {
    font-size: 8.5rem;
  }

  .flagtext {
    font-size: 0.7rem;
  }
}

@media (max-width: 600px) {
  .flag {
    font-size: 6.5rem;
  }

  .flagtext {
    font-size: 0.7rem;
  }
}

@media (max-width: 400px) {
  .flag {
    font-size: 7.0rem;
  }

  .flagtext {
    font-size: 0.6rem;
  }
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
</style>