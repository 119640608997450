export const flags = [
  { name: 'Afghanistan', emoji: '🇦🇫', similar: ['🇵🇰', '🇲🇻', '🇮🇷', '🇮🇶', '🇾🇪', '🇯🇴', '🇸🇦', '🇸🇾'] },
  { name: 'Albania', emoji: '🇦🇱', similar: ['🇲🇪', '🇰🇿', '🇧🇾', '🇬🇪', '🇦🇲', '🇽🇰', '🇹🇷', '🇲🇪'] },
  { name: 'Algeria', emoji: '🇩🇿', similar: ['🇱🇾', '🇲🇦', '🇵🇰', '🇲🇻', '🇦🇿', '🇹🇲', '🇨🇩', '🇮🇶'] },
  { name: 'Andorra', emoji: '🇦🇩', similar: ['🇷🇴', '🇲🇩', '🇪🇸', '🇫🇷', '🇧🇪', '🇺🇦', '🇱🇺', '🇹🇲'] },
  { name: 'Angola', emoji: '🇦🇴', similar: ['🇲🇿', '🇬🇳', '🇪🇹', '🇰🇪', '🇨🇬', '🇺🇬', '🇨🇲', '🇬🇼'] },
  { name: 'Antigua and Barbuda', emoji: '🇦🇬', similar: ['🇻🇨', '🇻🇮', '🇦🇮', '🇧🇧', '🇨🇺', '🇩🇲', '🇬🇩', '🇬🇾'] },
  { name: 'Argentina', emoji: '🇦🇷', similar: ['🇺🇾', '🇳🇮', '🇭🇳', '🇸🇰', '🇸🇮', '🇸🇲', '🇨🇷', '🇭🇷'] },
  { name: 'Armenia', emoji: '🇦🇲', similar: ['🇹🇩', '🇷🇴', '🇲🇩', '🇨🇴', '🇪🇨', '🇧🇪', '🇬🇾', '🇦🇼'] },
  { name: 'Australia', emoji: '🇦🇺', similar: ['🇳🇿', '🇬🇧', '🇫🇰', '🇹🇻', '🇵🇳', '🇫🇯', '🇳🇷', '🇵🇼'] },
  { name: 'Austria', emoji: '🇦🇹', similar: ['🇱🇻', '🇵🇱', '🇨🇮', '🇲🇱', '🇨🇭', '🇩🇪', '🇨🇱', '🇸🇮'] },
  { name: 'Azerbaijan', emoji: '🇦🇿', similar: ['🇺🇿', '🇹🇲', '🇹🇷', '🇹🇯', '🇰🇿', '🇮🇶', '🇮🇷', '🇷🇼'] },
  { name: 'Bahamas', emoji: '🇧🇸', similar: ['🇦🇮', '🇦🇬', '🇧🇧', '🇧🇲', '🇩🇲', '🇩🇴', '🇻🇨', '🇻🇮'] },
  { name: 'Bahrain', emoji: '🇧🇭', similar: ['🇶🇦', '🇦🇪', '🇴🇲', '🇰🇼', '🇸🇦', '🇾🇪', '🇯🇴', '🇮🇶'] },
  { name: 'Bangladesh', emoji: '🇧🇩', similar: ['🇵🇰', '🇦🇫', '🇳🇵', '🇮🇳', '🇱🇰', '🇲🇻', '🇲🇾', '🇲🇷'] },
  { name: 'Barbados', emoji: '🇧🇧', similar: ['🇧🇸', '🇦🇬', '🇧🇲', '🇨🇺', '🇩🇲', '🇩🇴', '🇻🇨', '🇻🇮'] },
  { name: 'Belarus', emoji: '🇧🇾', similar: ['🇷🇺', '🇺🇦', '🇲🇩', '🇱🇻', '🇱🇹', '🇵🇱', '🇨🇿', '🇲🇪'] },
  { name: 'Belgium', emoji: '🇧🇪', similar: ['🇩🇪', '🇹🇳', '🇬🇲', '🇱🇹', '🇷🇴', '🇹🇩', '🇨🇴', '🇪🇨'] },
  { name: 'Belize', emoji: '🇧🇿', similar: ['🇬🇹', '🇭🇳', '🇸🇻', '🇳🇮', '🇵🇦', '🇨🇷', '🇲🇽', '🇨🇺'] },
  { name: 'Benin', emoji: '🇧🇯', similar: ['🇧🇯', '🇨🇮', '🇲🇱', '🇬🇭', '🇬🇳', '🇨🇬', '🇬🇦', '🇬🇲'] },
  { name: 'Bhutan', emoji: '🇧🇹', similar: ['🇱🇰', '🇳🇵', '🇧🇩', '🇲🇻', '🇮🇳', '🇵🇰', '🇦🇫', '🇦🇿'] },
  { name: 'Bolivia', emoji: '🇧🇴', similar: ['🇨🇴', '🇪🇨', '🇵🇪', '🇻🇪', '🇬🇹', '🇭🇳', '🇸🇻', '🇳🇮'] },
  { name: 'Bosnia and Herzegovina', emoji: '🇧🇦', similar: ['🇸🇲', '🇸🇪', '🇱🇰', '🇺🇿', '🇺🇦', '🇪🇷', '🇪🇺', '🇸🇮'] },
  { name: 'Botswana', emoji: '🇧🇼', similar: ['🇺🇾', '🇦🇷', '🇬🇹', '🇭🇳', '🇵🇪', '🇨🇴', '🇵🇷', '🇨🇺'] },
  { name: 'Brazil', emoji: '🇧🇷', similar: ['🇨🇺', '🇩🇴', '🇸🇻', '🇳🇮', '🇵🇦', '🇨🇷', '🇲🇽', '🇦🇷'] },
  { name: 'Brunei', emoji: '🇧🇳', similar: ['🇲🇾', '🇮🇩', '🇸🇬', '🇹🇭', '🇵🇭', '🇻🇳', '🇱🇦', '🇲🇲'] },
  { name: 'Bulgaria', emoji: '🇧🇬', similar: ['🇭🇺', '🇮🇹', '🇲🇽', '🇳🇬', '🇳🇮', '🇧🇾', '🇨🇷', '🇭🇷'] },
  { name: 'Burkina Faso', emoji: '🇧🇫', similar: ['🇨🇬', '🇬🇦', '🇨🇲', '🇹🇬', '🇳🇪', '🇱🇾', '🇨🇫', '🇬🇭'] },
  { name: 'Burundi', emoji: '🇧🇮', similar: ['🇰🇪', '🇷🇼', '🇺🇬', '🇹🇿', '🇨🇬', '🇲🇿', '🇨🇲', '🇬🇲'] },
  { name: 'Cabo Verde', emoji: '🇨🇻', similar: ['🇨🇻', '🇵🇹', '🇦🇴', '🇬🇶', '🇲🇿', '🇸🇹', '🇹🇱', '🇧🇷'] },
  { name: 'Cambodia', emoji: '🇰🇭', similar: ['🇹🇭', '🇱🇦', '🇻🇳', '🇵🇭', '🇲🇲', '🇱🇰', '🇧🇳', '🇧🇩'] },
  { name: 'Cameroon', emoji: '🇨🇲', similar: ['🇬🇳', '🇨🇬', '🇬🇦', '🇹🇬', '🇳🇪', '🇱🇾', '🇨🇫', '🇧🇫'] },
  { name: 'Canada', emoji: '🇨🇦', similar: ['🇺🇸', '🇲🇽', '🇨🇺', '🇩🇴', '🇨🇱', '🇦🇷', '🇵🇪', '🇧🇷'] },
  { name: 'Central African Republic', emoji: '🇨🇫', similar: ['🇬🇦', '🇨🇬', '🇲🇱', '🇨🇲', '🇸🇳', '🇳🇪', '🇧🇫', '🇧🇯'] },
  { name: 'Chad', emoji: '🇹🇩', similar: ['🇷🇴', '🇲🇩', '🇨🇴', '🇪🇨', '🇧🇪', '🇪🇸', '🇦🇼', '🇩🇿'] },
  { name: 'Chile', emoji: '🇨🇱', similar: ['🇨🇺', '🇨🇷', '🇵🇾', '🇧🇴', '🇻🇪', '🇵🇦', '🇲🇽', '🇦🇷'] },
  { name: 'China', emoji: '🇨🇳', similar: ['🇻🇳', '🇰🇵', '🇸🇬', '🇹🇼', '🇭🇰', '🇱🇦', '🇰🇷', '🇲🇴'] },
  { name: 'Colombia', emoji: '🇨🇴', similar: ['🇪🇨', '🇻🇪', '🇵🇪', '🇧🇴', '🇭🇳', '🇳🇮', '🇸🇻', '🇬🇹'] },
  { name: 'Comoros', emoji: '🇰🇲', similar: ['🇸🇨', '🇲🇿', '🇲🇬', '🇲🇺', '🇷🇼', '🇺🇬', '🇹🇿', '🇹🇬'] },
  { name: 'Congo (Congo-Brazzaville)', emoji: '🇨🇬', similar: ['🇨🇲', '🇬🇦', '🇹🇬', '🇧🇫', '🇧🇮', '🇨🇫', '🇬🇲', '🇳🇪'] },
  { name: 'Costa Rica', emoji: '🇨🇷', similar: ['🇵🇦', '🇨🇴', '🇨🇺', '🇩🇴', '🇧🇴', '🇻🇪', '🇲🇽', '🇭🇳'] },
  { name: 'Croatia', emoji: '🇭🇷', similar: ['🇨🇿', '🇸🇮', '🇸🇰', '🇷🇸', '🇧🇦', '🇲🇰', '🇧🇬', '🇭🇺'] },
  { name: 'Cuba', emoji: '🇨🇺', similar: ['🇨🇷', '🇵🇦', '🇨🇱', '🇦🇷', '🇲🇽', '🇧🇷', '🇻🇪', '🇵🇾'] },
  { name: 'Cyprus', emoji: '🇨🇾', similar: ['🇬🇷', '🇱🇧', '🇲🇹', '🇮🇹', '🇪🇸', '🇹🇷', '🇦🇲', '🇧🇬'] },
  { name: 'Czechia (Czech Republic)', emoji: '🇨🇿', similar: ['🇸🇰', '🇭🇷', '🇸🇮', '🇵🇱', '🇷🇸', '🇲🇰', '🇧🇦', '🇭🇺'] },
  { name: 'Democratic Republic of the Congo', emoji: '🇨🇩', similar: ['🇦🇴', '🇬🇳', '🇪🇹', '🇰🇪', '🇨🇬', '🇺🇬', '🇨🇲', '🇬🇼'] },
  { name: 'Denmark', emoji: '🇩🇰', similar: ['🇸🇪', '🇳🇴', '🇫🇮', '🇮🇸', '🇬🇱', '🇫🇴', '🇪🇪', '🇷🇺'] },
  { name: 'Djibouti', emoji: '🇩🇯', similar: ['🇪🇷', '🇸🇴', '🇪🇹', '🇾🇪', '🇰🇪', '🇹🇿', '🇲🇬', '🇲🇿'] },
  { name: 'Dominica', emoji: '🇩🇲', similar: ['🇦🇬', '🇧🇧', '🇨🇺', '🇩🇴', '🇻🇨', '🇻🇮', '🇧🇸', '🇬🇾'] },
  { name: 'Dominican Republic', emoji: '🇩🇴', similar: ['🇨🇺', '🇨🇷', '🇵🇦', '🇨🇴', '🇲🇽', '🇧🇷', '🇻🇪', '🇵🇾'] },
  { name: 'Ecuador', emoji: '🇪🇨', similar: ['🇨🇴', '🇻🇪', '🇵🇪', '🇧🇴', '🇭🇳', '🇳🇮', '🇸🇻', '🇬🇹'] },
  { name: 'Egypt', emoji: '🇪🇬', similar: ['🇮🇶', '🇯🇴', '🇸🇾', '🇱🇧', '🇾🇪', '🇲🇦', '🇱🇾', '🇹🇳'] },
  { name: 'El Salvador', emoji: '🇸🇻', similar: ['🇳🇮', '🇭🇳', '🇨🇷', '🇵🇦', '🇨🇺', '🇩🇴', '🇧🇴', '🇲🇽'] },
  { name: 'Equatorial Guinea', emoji: '🇬🇶', similar: ['🇦🇴', '🇬🇳', '🇪🇹', '🇰🇪', '🇨🇬', '🇺🇬', '🇨🇲', '🇬🇼'] },
  { name: 'Eritrea', emoji: '🇪🇷', similar: ['🇪🇹', '🇩🇯', '🇸🇴', '🇸🇩', '🇿🇲', '🇲🇿', '🇲🇬', '🇾🇪'] },
  { name: 'Estonia', emoji: '🇪🇪', similar: ['🇫🇮', '🇸🇪', '🇩🇰', '🇱🇻', '🇱🇹', '🇳🇴', '🇮🇸', '🇷🇺'] },
  { name: 'Eswatini', emoji: '🇸🇿', similar: ['🇿🇦', '🇳🇦', '🇧🇼', '🇲🇿', '🇲🇼', '🇱🇸', '🇿🇲', '🇰🇪'] },
  { name: 'Ethiopia', emoji: '🇪🇹', similar: ['🇪🇷', '🇸🇩', '🇸🇴', '🇰🇪', '🇹🇿', '🇺🇬', '🇷🇼', '🇧🇮'] },
  { name: 'Fiji', emoji: '🇫🇯', similar: ['🇹🇻', '🇦🇺', '🇳🇿', '🇬🇧', '🇵🇼', '🇳🇷', '🇫🇰', '🇵🇬'] },
  { name: 'Finland', emoji: '🇫🇮', similar: ['🇸🇪', '🇩🇰', '🇳🇴', '🇮🇸', '🇪🇪', '🇷🇺', '🇱🇹', '🇱🇻'] },
  { name: 'France', emoji: '🇫🇷', similar: ['🇳🇱', '🇱🇺', '🇷🇸', '🇸🇮', '🇸🇲', '🇸🇰', '🇷🇺', '🇸🇰'] },
  { name: 'Gabon', emoji: '🇬🇦', similar: ['🇨🇩', '🇲🇬', '🇲🇼', '🇲🇿', '🇳🇦', '🇳🇬', '🇳🇮', '🇸🇿'] },
  { name: 'Gambia', emoji: '🇬🇲', similar: ['🇱🇷', '🇸🇱', '🇸🇳', '🇬🇳', '🇬🇼', '🇬🇦', '🇨🇲', '🇹🇬'] },
  { name: 'Georgia', emoji: '🇬🇪', similar: ['🇦🇲', '🇦🇿', '🇺🇦', '🇧🇾', '🇷🇺', '🇰🇿', '🇰🇬', '🇺🇿'] },
  { name: 'Germany', emoji: '🇩🇪', similar: ['🇧🇪', '🇧🇬', '🇬🇭', '🇬🇳', '🇳🇪', '🇺🇬', '🇹🇬', '🇪🇹'] },
  { name: 'Ghana', emoji: '🇬🇭', similar: ['🇬🇳', '🇨🇮', '🇬🇦', '🇹🇬', '🇨🇲', '🇧🇯', '🇧🇫', '🇲🇱'] },
  { name: 'Greece', emoji: '🇬🇷', similar: ['🇦🇷', '🇭🇳', '🇳🇮', '🇸🇮', '🇸🇰', '🇸🇲', '🇭🇷', '🇸🇪'] },
  { name: 'Grenada', emoji: '🇬🇩', similar: ['🇧🇧', '🇧🇲', '🇨🇺', '🇩🇴', '🇩🇲', '🇻🇨', '🇻🇮', '🇧🇧'] },
  { name: 'Guatemala', emoji: '🇬🇹', similar: ['🇳🇮', '🇸🇻', '🇨🇴', '🇧🇴', '🇭🇳', '🇸🇻', '🇧🇸', '🇧🇿'] },
  { name: 'Guinea', emoji: '🇬🇳', similar: ['🇬🇭', '🇨🇲', '🇧🇯', '🇲🇱', '🇹🇬', '🇨🇬', '🇬🇲', '🇬🇦'] },
  { name: 'Guinea-Bissau', emoji: '🇬🇼', similar: ['🇬🇭', '🇬🇳', '🇨🇮', '🇧🇯', '🇲🇱', '🇹🇬', '🇨🇲', '🇨🇬'] },
  { name: 'Guyana', emoji: '🇬🇾', similar: ['🇯🇲', '🇧🇧', '🇨🇺', '🇩🇴', '🇧🇿', '🇧🇸', '🇬🇧', '🇹🇬'] },
  { name: 'Haiti', emoji: '🇭🇹', similar: ['🇨🇺', '🇩🇴', '🇧🇿', '🇧🇸', '🇨🇼', '🇨🇼', '🇸🇷', '🇸🇻'] },
  { name: 'Honduras', emoji: '🇭🇳', similar: ['🇳🇮', '🇸🇻', '🇬🇹', '🇧🇴', '🇨🇷', '🇨🇴', '🇵🇦', '🇸🇻'] },
  { name: 'Hungary', emoji: '🇭🇺', similar: ['🇮🇹', '🇧🇬', '🇲🇽', '🇳🇬', '🇳🇮', '🇧🇾', '🇨🇷', '🇭🇷'] },
  { name: 'Iceland', emoji: '🇮🇸', similar: ['🇫🇴', '🇳🇴', '🇸🇪', '🇩🇰', '🇫🇮', '🇬🇱', '🇬🇧', '🇪🇪'] },
  { name: 'India', emoji: '🇮🇳', similar: ['🇳🇵', '🇧🇩', '🇵🇰', '🇦🇫', '🇱🇰', '🇲🇾', '🇮🇩', '🇵🇭'] },
  { name: 'Indonesia', emoji: '🇮🇩', similar: ['🇲🇴', '🇵🇱', '🇸🇬', '🇹🇭', '🇱🇦', '🇰🇭', '🇲🇾', '🇹🇼'] },
  { name: 'Iran', emoji: '🇮🇷', similar: ['🇮🇶', '🇵🇰', '🇦🇫', '🇸🇦', '🇾🇪', '🇸🇾', '🇱🇧', '🇯🇴'] },
  { name: 'Iraq', emoji: '🇮🇶', similar: ['🇪🇬', '🇾🇪', '🇱🇧', '🇮🇷', '🇸🇦', '🇦🇪', '🇰🇼', '🇧🇭'] },
  { name: 'Ireland', emoji: '🇮🇪', similar: ['🇮🇹', '🇮🇨', '🇳🇬', '🇳🇮', '🇲🇽', '🇧🇬', '🇭🇷', '🇭🇺'] },
  { name: 'Israel', emoji: '🇮🇱', similar: ['🇸🇪', '🇩🇰', '🇸🇮', '🇸🇰', '🇧🇦', '🇷🇸', '🇭🇷', '🇲🇰'] },
  { name: 'Italy', emoji: '🇮🇹', similar: ['🇮🇪', '🇲🇽', '🇭🇺', '🇳🇬', '🇳🇮', '🇧🇬', '🇨🇷', '🇭🇷'] },
  { name: 'Jamaica', emoji: '🇯🇲', similar: ['🇬🇾', '🇧🇧', '🇨🇺', '🇩🇴', '🇧🇿', '🇧🇸', '🇧🇷', '🇸🇷'] },
  { name: 'Japan', emoji: '🇯🇵', similar: ['🇧🇩', '🇰🇵', '🇰🇷', '🇲🇴', '🇼🇸', '🇹🇼', '🇹🇭', '🇲🇾'] },
  { name: 'Jordan', emoji: '🇯🇴', similar: ['🇸🇦', '🇦🇪', '🇧🇭', '🇰🇼', '🇶🇦', '🇴🇲', '🇾🇪', '🇸🇾'] },
  { name: 'Kazakhstan', emoji: '🇰🇿', similar: ['🇺🇿', '🇹🇲', '🇹🇯', '🇰🇬', '🇷🇺', '🇧🇾', '🇺🇦', '🇲🇳'] },
  { name: 'Kenya', emoji: '🇰🇪', similar: ['🇹🇿', '🇺🇬', '🇷🇼', '🇧🇮', '🇲🇿', '🇿🇲', '🇲🇼', '🇹🇿'] },
  { name: 'Kiribati', emoji: '🇰🇮', similar: ['🇹🇻', '🇫🇲', '🇳🇷', '🇵🇼', '🇼🇸', '🇹🇴', '🇸🇧', '🇻🇺'] },
  { name: 'Kuwait', emoji: '🇰🇼', similar: ['🇦🇪', '🇧🇭', '🇶🇦', '🇴🇲', '🇸🇦', '🇾🇪', '🇯🇴', '🇮🇶'] },
  { name: 'Kyrgyzstan', emoji: '🇰🇬', similar: ['🇺🇿', '🇹🇲', '🇰🇿', '🇹🇯', '🇦🇿', '🇷🇺', '🇨🇳', '🇧🇾'] },
  { name: 'Laos', emoji: '🇱🇦', similar: ['🇹🇭', '🇰🇭', '🇲🇲', '🇻🇳', '🇲🇾', '🇮🇩', '🇵🇭', '🇸🇬'] },
  { name: 'Latvia', emoji: '🇱🇻', similar: ['🇦🇹', '🇵🇱', '🇮🇹', '🇨🇭', '🇧🇬', '🇭🇷', '🇸🇮', '🇨🇿'] },
  { name: 'Lebanon', emoji: '🇱🇧', similar: ['🇯🇴', '🇸🇾', '🇮🇶', '🇪🇬', '🇮🇷', '🇰🇼', '🇧🇭', '🇴🇲'] },
  { name: 'Lesotho', emoji: '🇱🇸', similar: ['🇧🇼', '🇸🇿', '🇲🇼', '🇿🇦', '🇳🇦', '🇿🇲', '🇲🇬', '🇲🇿'] },
  { name: 'Liberia', emoji: '🇱🇷', similar: ['🇺🇸', '🇸🇱', '🇬🇲', '🇬🇭', '🇬🇳', '🇬🇼', '🇨🇮', '🇧🇯'] },
  { name: 'Libya', emoji: '🇱🇾', similar: ['🇪🇬', '🇹🇳', '🇲🇦', '🇮🇶', '🇸🇦', '🇾🇪', '🇸🇾', '🇯🇴'] },
  { name: 'Liechtenstein', emoji: '🇱🇮', similar: ['🇸🇮', '🇸🇰', '🇸🇲', '🇦🇹', '🇨🇿', '🇨🇭', '🇩🇪', '🇱🇺'] },
  { name: 'Lithuania', emoji: '🇱🇹', similar: ['🇪🇪', '🇱🇻', '🇫🇮', '🇸🇪', '🇩🇰', '🇵🇱', '🇨🇿', '🇧🇬'] },
  { name: 'Luxembourg', emoji: '🇱🇺', similar: ['🇳🇱', '🇧🇪', '🇫🇷', '🇷🇸', '🇸🇮', '🇸🇲', '🇸🇰', '🇨🇿'] },
  { name: 'Madagascar', emoji: '🇲🇬', similar: ['🇰🇪', '🇹🇿', '🇲🇼', '🇺🇬', '🇷🇼', '🇧🇮', '🇿🇦', '🇧🇼'] },
  { name: 'Malawi', emoji: '🇲🇼', similar: ['🇲🇿', '🇿🇦', '🇿🇲', '🇹🇿', '🇰🇪', '🇺🇬', '🇷🇼', '🇧🇮'] },
  { name: 'Malaysia', emoji: '🇲🇾', similar: ['🇵🇭', '🇸🇬', '🇮🇩', '🇧🇳', '🇹🇭', '🇱🇦', '🇻🇳', '🇲🇲'] },
  { name: 'Maldives', emoji: '🇲🇻', similar: ['🇵🇰', '🇧🇩', '🇳🇵', '🇱🇰', '🇮🇳', '🇲🇾', '🇲🇳', '🇦🇿'] },
  { name: 'Mali', emoji: '🇲🇱', similar: ['🇬🇳', '🇨🇲', '🇧🇯', '🇱🇹', '🇬🇲', '🇨🇫', '🇧🇮', '🇹🇬'] },
  { name: 'Malta', emoji: '🇲🇹', similar: ['🇨🇭', '🇦🇹', '🇸🇮', '🇸🇰', '🇸🇲', '🇱🇻', '🇵🇱', '🇱🇹'] },
  { name: 'Marshall Islands', emoji: '🇲🇭', similar: ['🇼🇸', '🇹🇴', '🇸🇧', '🇻🇺', '🇫🇯', '🇵🇼', '🇹🇻', '🇦🇸'] },
  { name: 'Mauritania', emoji: '🇲🇷', similar: ['🇸🇳', '🇲🇱', '🇬🇳', '🇬🇦', '🇨🇬', '🇨🇫', '🇬🇭', '🇬🇲'] },
  { name: 'Mauritius', emoji: '🇲🇺', similar: ['🇲🇬', '🇲🇿', '🇰🇪', '🇹🇿', '🇲🇼', '🇿🇦', '🇷🇼', '🇺🇬'] },
  { name: 'Mexico', emoji: '🇲🇽', similar: ['🇭🇳', '🇳🇮', '🇨🇷', '🇸🇻', '🇨🇺', '🇩🇴', '🇵🇦', '🇨🇱'] },
  { name: 'Micronesia', emoji: '🇫🇲', similar: ['🇼🇸', '🇹🇴', '🇸🇧', '🇻🇺', '🇫🇯', '🇵🇼', '🇹🇻', '🇦🇸'] },
  { name: 'Moldova', emoji: '🇲🇩', similar: ['🇷🇴', '🇹🇩', '🇨🇴', '🇪🇨', '🇧🇪', '🇧🇦', '🇭🇷', '🇲🇪'] },
  { name: 'Monaco', emoji: '🇲🇨', similar: ['🇵🇱', '🇮🇩', '🇲🇴', '🇸🇬', '🇱🇻', '🇱🇹', '🇧🇬', '🇧🇭'] },
  { name: 'Mongolia', emoji: '🇲🇳', similar: ['🇧🇹', '🇱🇰', '🇧🇩', '🇳🇵', '🇮🇳', '🇵🇰', '🇦🇫', '🇲🇾'] },
  { name: 'Montenegro', emoji: '🇲🇪', similar: ['🇷🇸', '🇧🇦', '🇭🇷', '🇸🇮', '🇲🇰', '🇦🇱', '🇧🇬', '🇭🇺'] },
  { name: 'Morocco', emoji: '🇲🇦', similar: ['🇩🇿', '🇹🇳', '🇱🇾', '🇪🇬', '🇸🇦', '🇯🇴', '🇦🇪', '🇸🇾'] },
  { name: 'Mozambique', emoji: '🇲🇿', similar: ['🇿🇼', '🇿🇲', '🇲🇼', '🇹🇿', '🇰🇪', '🇺🇬', '🇷🇼', '🇧🇮'] },
  { name: 'Myanmar (Burma)', emoji: '🇲🇲', similar: ['🇹🇭', '🇱🇦', '🇰🇭', '🇲🇾', '🇸🇬', '🇧🇳', '🇵🇭', '🇮🇩'] },
  { name: 'Namibia', emoji: '🇳🇦', similar: ['🇿🇦', '🇿🇲', '🇲🇿', '🇧🇼', '🇲🇼', '🇹🇿', '🇰🇪', '🇺🇬'] },
  { name: 'Nauru', emoji: '🇳🇷', similar: ['🇹🇻', '🇫🇯', '🇳🇿', '🇦🇺', '🇵🇼', '🇼🇸', '🇹🇴', '🇸🇧'] },
  { name: 'Nepal', emoji: '🇳🇵', similar: ['🇱🇰', '🇮🇳', '🇧🇩', '🇵🇰', '🇦🇫', '🇲🇻', '🇲🇾', '🇵🇭'] },
  { name: 'Netherlands', emoji: '🇳🇱', similar: ['🇱🇺', '🇫🇷', '🇸🇲', '🇸🇰', '🇨🇿', '🇧🇪', '🇩🇪', '🇦🇹'] },
  { name: 'New Zealand', emoji: '🇳🇿', similar: ['🇦🇺', '🇬🇧', '🇫🇯', '🇵🇬', '🇹🇻', '🇵🇳', '🇳🇷', '🇵🇼'] },
  { name: 'Nicaragua', emoji: '🇳🇮', similar: ['🇸🇻', '🇬🇹', '🇭🇳', '🇨🇷', '🇲🇽', '🇨🇴', '🇨🇺', '🇵🇦'] },
  { name: 'Niger', emoji: '🇳🇪', similar: ['🇮🇳', '🇭🇺', '🇮🇹', '🇮🇪', '🇲🇽', '🇧🇬', '🇨🇮', '🇲🇱'] },
  { name: 'Nigeria', emoji: '🇳🇬', similar: ['🇳🇪', '🇨🇮', '🇲🇱', '🇬🇭', '🇬🇲', '🇧🇯', '🇨🇲', '🇨🇩'] },
  { name: 'North Korea', emoji: '🇰🇵', similar: ['🇰🇷', '🇨🇳', '🇻🇳', '🇵🇭', '🇰🇭', '🇹🇭', '🇲🇾', '🇯🇵'] },
  { name: 'North Macedonia', emoji: '🇲🇰', similar: ['🇷🇸', '🇧🇬', '🇧🇦', '🇭🇷', '🇲🇪', '🇦🇱', '🇸🇮', '🇬🇷'] },
  { name: 'Norway', emoji: '🇳🇴', similar: ['🇸🇪', '🇫🇮', '🇩🇰', '🇮🇸', '🇪🇪', '🇱🇻', '🇱🇹', '🇷🇺'] },
  { name: 'Oman', emoji: '🇴🇲', similar: ['🇦🇪', '🇧🇭', '🇶🇦', '🇰🇼', '🇸🇦', '🇾🇪', '🇯🇴', '🇮🇶'] },
  { name: 'Pakistan', emoji: '🇵🇰', similar: ['🇧🇩', '🇦🇫', '🇮🇳', '🇳🇵', '🇱🇰', '🇲🇻', '🇲🇾', '🇧🇳'] },
  { name: 'Palau', emoji: '🇵🇼', similar: ['🇫🇲', '🇼🇸', '🇹🇴', '🇸🇧', '🇻🇺', '🇹🇻', '🇫🇯', '🇦🇸'] },
  { name: 'Palestine', emoji: '🇵🇸', similar: ['🇯🇴', '🇸🇾', '🇱🇧', '🇮🇶', '🇪🇬', '🇸🇦', '🇾🇪', '🇶🇦'] },
  { name: 'Panama', emoji: '🇵🇦', similar: ['🇨🇴', '🇵🇷', '🇸🇻', '🇸🇻', '🇳🇮', '🇨🇷', '🇨🇺', '🇩🇴'] },
  { name: 'Papua New Guinea', emoji: '🇵🇬', similar: ['🇦🇺', '🇳🇿', '🇫🇯', '🇼🇸', '🇹🇴', '🇸🇧', '🇻🇺', '🇵🇼'] },
  { name: 'Paraguay', emoji: '🇵🇾', similar: ['🇨🇴', '🇪🇨', '🇻🇪', '🇵🇪', '🇧🇴', '🇭🇳', '🇳🇮', '🇬🇹'] },
  { name: 'Peru', emoji: '🇵🇪', similar: ['🇲🇲', '🇲🇬', '🇰🇪', '🇹🇿', '🇲🇼', '🇿🇦', '🇺🇬', '🇧🇼'] },
  { name: 'Philippines', emoji: '🇵🇭', similar: ['🇲🇾', '🇹🇭', '🇮🇩', '🇸🇬', '🇧🇳', '🇰🇭', '🇻🇳', '🇱🇦'] },
  { name: 'Poland', emoji: '🇵🇱', similar: ['🇲🇨', '🇯🇵', '🇹🇼', '🇮🇩', '🇸🇬', '🇰🇷', '🇵🇹', '🇧🇭'] },
  { name: 'Portugal', emoji: '🇵🇹', similar: ['🇧🇷', '🇨🇻', '🇲🇿', '🇦🇴', '🇸🇹', '🇹🇱', '🇨🇭', '🇫🇷'] },
  { name: 'Qatar', emoji: '🇶🇦', similar: ['🇧🇭', '🇦🇪', '🇴🇲', '🇰🇼', '🇸🇦', '🇾🇪', '🇯🇴', '🇮🇶'] },
  { name: 'Romania', emoji: '🇷🇴', similar: ['🇲🇩', '🇧🇪', '🇨🇴', '🇪🇨', '🇦🇼', '🇭🇷', '🇷🇸', '🇧🇬'] },
  { name: 'Russia', emoji: '🇷🇺', similar: ['🇷🇸', '🇨🇿', '🇸🇰', '🇸🇮', '🇵🇱', '🇧🇬', '🇺🇦', '🇧🇾'] },
  { name: 'Rwanda', emoji: '🇷🇼', similar: ['🇧🇮', '🇺🇬', '🇰🇪', '🇹🇿', '🇲🇿', '🇿🇲', '🇲🇼', '🇧🇼'] },
  { name: 'Saint Kitts and Nevis', emoji: '🇰🇳', similar: ['🇧🇸', '🇦🇬', '🇧🇧', '🇧🇿', '🇧🇲', '🇩🇲', '🇻🇨', '🇻🇮'] },
  { name: 'Saint Lucia', emoji: '🇱🇨', similar: ['🇻🇨', '🇦🇬', '🇧🇧', '🇧🇲', '🇧🇿', '🇩🇲', '🇧🇧', '🇻🇮'] },
  { name: 'Saint Vincent and the Grenadines', emoji: '🇻🇨', similar: ['🇧🇸', '🇦🇬', '🇧🇧', '🇧🇿', '🇧🇲', '🇩🇲', '🇻🇮', '🇱🇨'] },
  { name: 'Samoa', emoji: '🇼🇸', similar: ['🇹🇴', '🇸🇧', '🇻🇺', '🇹🇻', '🇫🇯', '🇵🇼', '🇳🇷', '🇵🇬'] },
  { name: 'San Marino', emoji: '🇸🇲', similar: ['🇸🇮', '🇸🇰', '🇸🇮', '🇱🇺', '🇳🇱', '🇸🇪', '🇭🇷', '🇷🇸'] },
  { name: 'São Tomé and Príncipe', emoji: '🇸🇹', similar: ['🇨🇬', '🇦🇴', '🇲🇬', '🇹🇿', '🇲🇼', '🇿🇦', '🇲🇿', '🇲🇬'] },
  { name: 'Saudi Arabia', emoji: '🇸🇦', similar: ['🇯🇴', '🇧🇭', '🇶🇦', '🇴🇲', '🇰🇼', '🇾🇪', '🇸🇾', '🇦🇪'] },
  { name: 'Senegal', emoji: '🇸🇳', similar: ['🇲🇱', '🇬🇳', '🇨🇲', '🇬🇦', '🇧🇯', '🇧🇫', '🇬🇼', '🇬🇭'] },
  { name: 'Serbia', emoji: '🇷🇸', similar: ['🇧🇦', '🇲🇪', '🇭🇷', '🇸🇮', '🇧🇬', '🇲🇰', '🇦🇱', '🇭🇺'] },
  { name: 'Seychelles', emoji: '🇸🇨', similar: ['🇨🇬', '🇲🇬', '🇹🇿', '🇲🇼', '🇿🇦', '🇲🇿', '🇷🇼', '🇧🇼'] },
  { name: 'Sierra Leone', emoji: '🇸🇱', similar: ['🇱🇷', '🇬🇭', '🇬🇲', '🇨🇮', '🇧🇯', '🇨🇲', '🇨🇩', '🇬🇳'] },
  { name: 'Singapore', emoji: '🇸🇬', similar: ['🇲🇾', '🇮🇩', '🇵🇭', '🇹🇭', '🇻🇳', '🇧🇳', '🇰🇭', '🇱🇦'] },
  { name: 'Slovakia', emoji: '🇸🇰', similar: ['🇨🇿', '🇷🇸', '🇸🇮', '🇭🇷', '🇧🇬', '🇵🇱', '🇭🇺', '🇲🇰'] },
  { name: 'Slovenia', emoji: '🇸🇮', similar: ['🇸🇲', '🇸🇰', '🇧🇬', '🇨🇿', '🇷🇸', '🇭🇷', '🇲🇰', '🇧🇦'] },
  { name: 'Solomon Islands', emoji: '🇸🇧', similar: ['🇼🇸', '🇹🇴', '🇻🇺', '🇹🇻', '🇫🇯', '🇵🇼', '🇳🇷', '🇵🇬'] },
  { name: 'Somalia', emoji: '🇸🇴', similar: ['🇸🇩', '🇪🇷', '🇪🇹', '🇨🇫', '🇨🇲', '🇬🇦', '🇨🇬', '🇹🇩'] },
  { name: 'South Africa', emoji: '🇿🇦', similar: ['🇳🇦', '🇿🇲', '🇲🇿', '🇧🇼', '🇲🇼', '🇹🇿', '🇰🇪', '🇺🇬'] },
  { name: 'South Korea', emoji: '🇰🇷', similar: ['🇯🇵', '🇰🇵', '🇨🇳', '🇹🇼', '🇸🇬', '🇲🇴', '🇵🇭', '🇹🇭'] },
  { name: 'South Sudan', emoji: '🇸🇸', similar: ['🇸🇩', '🇺🇬', '🇰🇪', '🇹🇿', '🇷🇼', '🇧🇮', '🇲🇿', '🇲🇼'] },
  { name: 'Spain', emoji: '🇪🇸', similar: ['🇻🇪', '🇨🇴', '🇪🇨', '🇧🇴', '🇧🇿', '🇩🇲', '🇬🇹', '🇨🇱'] },
  { name: 'Sri Lanka', emoji: '🇱🇰', similar: ['🇮🇳', '🇳🇵', '🇧🇩', '🇵🇰', '🇲🇲', '🇲🇾', '🇮🇩', '🇹🇭'] },
  { name: 'Sudan', emoji: '🇸🇩', similar: ['🇪🇷', '🇪🇹', '🇸🇴', '🇨🇫', '🇨🇲', '🇬🇦', '🇨🇬', '🇹🇩'] },
  { name: 'Suriname', emoji: '🇸🇷', similar: ['🇬🇾', '🇧🇧', '🇨🇺', '🇩🇴', '🇧🇿', '🇧🇸', '🇧🇷', '🇸🇻'] },
  { name: 'Sweden', emoji: '🇸🇪', similar: ['🇫🇮', '🇩🇰', '🇳🇴', '🇮🇸', '🇪🇪', '🇱🇹', '🇷🇺', '🇳🇱'] },
  { name: 'Switzerland', emoji: '🇨🇭', similar: ['🇦🇹', '🇩🇪', '🇧🇪', '🇨🇿', '🇳🇱', '🇫🇷', '🇵🇱', '🇭🇺'] },
  { name: 'Syria', emoji: '🇸🇾', similar: ['🇱🇧', '🇯🇴', '🇮🇶', '🇾🇪', '🇮🇷', '🇧🇭', '🇰🇼', '🇸🇦'] },
  { name: 'Taiwan', emoji: '🇹🇼', similar: ['🇯🇵', '🇰🇷', '🇰🇵', '🇨🇳', '🇲🇴', '🇸🇬', '🇵🇭', '🇮🇩'] },
  { name: 'Tajikistan', emoji: '🇹🇯', similar: ['🇰🇬', '🇰🇿', '🇺🇿', '🇹🇲', '🇦🇲', '🇦🇿', '🇬🇪', '🇺🇦'] },
  { name: 'Tanzania', emoji: '🇹🇿', similar: ['🇰🇪', '🇺🇬', '🇷🇼', '🇧🇮', '🇲🇿', '🇿🇲', '🇲🇼', '🇧🇼'] },
  { name: 'Thailand', emoji: '🇹🇭', similar: ['🇱🇦', '🇰🇭', '🇲🇲', '🇻🇳', '🇲🇾', '🇮🇩', '🇵🇭', '🇧🇳'] },
  { name: 'Timor-Leste', emoji: '🇹🇱', similar: ['🇧🇳', '🇮🇩', '🇸🇬', '🇲🇾', '🇹🇭', '🇵🇭', '🇹🇼', '🇯🇵'] },
  { name: 'Togo', emoji: '🇹🇬', similar: ['🇧🇯', '🇨🇲', '🇬🇭', '🇨🇮', '🇧🇫', '🇧🇮', '🇲🇱', '🇬🇲'] },
  { name: 'Tonga', emoji: '🇹🇴', similar: ['🇼🇸', '🇸🇧', '🇻🇺', '🇹🇻', '🇫🇯', '🇵🇼', '🇳🇷', '🇵🇬'] },
  { name: 'Trinidad and Tobago', emoji: '🇹🇹', similar: ['🇯🇲', '🇬🇾', '🇧🇧', '🇨🇺', '🇩🇴', '🇧🇿', '🇧🇸', '🇧🇷'] },
  { name: 'Tunisia', emoji: '🇹🇳', similar: ['🇲🇦', '🇩🇿', '🇱🇾', '🇪🇬', '🇸🇩', '🇪🇹', '🇦🇪', '🇸🇦'] },
  { name: 'Turkey', emoji: '🇹🇷', similar: ['🇵🇰', '🇦🇿', '🇺🇿', '🇰🇿', '🇹🇲', '🇹🇯', '🇰🇬', '🇧🇷'] },
  { name: 'Turkmenistan', emoji: '🇹🇲', similar: ['🇰🇿', '🇺🇿', '🇰🇬', '🇹🇯', '🇦🇿', '🇧🇾', '🇺🇦', '🇲🇳'] },
  { name: 'Tuvalu', emoji: '🇹🇻', similar: ['🇼🇸', '🇹🇴', '🇸🇧', '🇻🇺', '🇫🇯', '🇵🇼', '🇳🇷', '🇵🇬'] },
  { name: 'Uganda', emoji: '🇺🇬', similar: ['🇰🇪', '🇹🇿', '🇷🇼', '🇧🇮', '🇲🇿', '🇿🇲', '🇲🇼', '🇧🇼'] },
  { name: 'Ukraine', emoji: '🇺🇦', similar: ['🇷🇺', '🇧🇾', '🇵🇱', '🇨🇿', '🇸🇰', '🇷🇸', '🇧🇬', '🇲🇰'] },
  { name: 'United Arab Emirates', emoji: '🇦🇪', similar: ['🇧🇭', '🇶🇦', '🇴🇲', '🇰🇼', '🇸🇦', '🇾🇪', '🇯🇴', '🇮🇶'] },
  { name: 'United Kingdom', emoji: '🇬🇧', similar: ['🇺🇸', '🇨🇦', '🇳🇿', '🇦🇺', '🇮🇪', '🇫🇯', '🇵🇬', '🇹🇻'] },
  { name: 'United States', emoji: '🇺🇸', similar: ['🇬🇧', '🇨🇦', '🇲🇽', '🇧🇿', '🇧🇸', '🇧🇸', '🇧🇧', '🇩🇴'] },
  { name: 'Uruguay', emoji: '🇺🇾', similar: ['🇦🇷', '🇨🇱', '🇵🇾', '🇧🇴', '🇧🇷', '🇵🇪', '🇨🇴', '🇻🇪'] },
  { name: 'Uzbekistan', emoji: '🇺🇿', similar: ['🇰🇿', '🇹🇲', '🇰🇬', '🇹🇯', '🇦🇲', '🇦🇿', '🇬🇪', '🇺🇦'] },
  { name: 'Vanuatu', emoji: '🇻🇺', similar: ['🇼🇸', '🇹🇴', '🇸🇧', '🇹🇻', '🇫🇯', '🇵🇼', '🇳🇷', '🇵🇬'] },
  { name: 'Vatican City', emoji: '🇻🇦', similar: ['🇸🇲', '🇸🇰', '🇱🇺', '🇦🇹', '🇮🇹', '🇧🇪', '🇳🇱', '🇩🇪'] },
  { name: 'Venezuela', emoji: '🇻🇪', similar: ['🇨🇴', '🇪🇨', '🇵🇪', '🇧🇴', '🇨🇺', '🇨🇱', '🇦🇷', '🇵🇦'] },
  { name: 'Vietnam', emoji: '🇻🇳', similar: ['🇨🇳', '🇱🇦', '🇰🇭', '🇲🇲', '🇹🇭', '🇵🇭', '🇲🇾', '🇮🇩'] },
  { name: 'Yemen', emoji: '🇾🇪', similar: ['🇸🇾', '🇮🇶', '🇸🇦', '🇯🇴', '🇧🇭', '🇶🇦', '🇴🇲', '🇦🇪'] },
  { name: 'Zambia', emoji: '🇿🇲', similar: ['🇲🇼', '🇹🇿', '🇰🇪', '🇺🇬', '🇷🇼', '🇧🇮', '🇲🇿', '🇿🇦'] },
  { name: 'Zimbabwe', emoji: '🇿🇼', similar: ['🇲🇿', '🇿🇲', '🇧🇼', '🇲🇼', '🇹🇿', '🇰🇪', '🇺🇬', '🇷🇼'] }
];

